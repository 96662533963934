<template>
  <div class="mx-4">
    <div class="relative m-auto z-10">
      <div class="header-search-page mt-6">
        <p class="text-center">انتخاب آدرس</p>
      </div>
      <div class="search__wrapper">
        <span class="svg-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.686" height="19.729" viewBox="0 0 19.686 19.729">
            <path id="Union_1" data-name="Union 1" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(0.5 0.5)" fill="none" stroke="#ff7400" stroke-width="1"/>
          </svg>
        </span>
        <input type="text" @input="filterRealEstate"  placeholder="بنگاه مورد نظر خود را وارد کنید">
        <span class="selectCity" @click="isShowCities = !isShowCities">{{ selectedCity }}</span>
      </div>
      <template v-if="isShowCities">
        <div class="city-search">
          <input v-model="filter" type="text" placeholder="شهر مورد نظرتو جستجو کن">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.686" height="19.729" viewBox="0 0 19.686 19.729">
            <path id="Union_3" data-name="Union 3" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(0.5 0.5)" fill="none" stroke="#afafaf" stroke-width="1"/>
          </svg>
        </div>
        <div class="list-city overflow-y-scroll scrollbar-hide h-screen">
          <p>نتایج جستجو</p>
          <ul>
            <li v-for="city in filterCities" :key="city.id" class="items" @click="handleSelectCity(city)">
              <h6>{{ city.name }}</h6>
              <span>{{city.province.name}}</span>
            </li>
          </ul>
        </div>
      </template>
      <div class="checkbox_wrapper justify-center" v-if="selectedRealEstates.length > 0">
        <span
            class="selected checkbox-custom text-white py-1 px-3 text-xs cursor-pointer"
            @click="removeSelectedRealEstate(area)" v-for="RealEstate in selectedRealEstates"
        >
          <svg class="inline" xmlns="http://www.w3.org/2000/svg" width="6.378" height="6.376" viewBox="0 0 6.378 6.376">
  <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M15.23,14.477,17.508,12.2a.534.534,0,1,0-.755-.755l-2.278,2.278L12.2,11.444a.534.534,0,1,0-.755.755l2.278,2.278-2.278,2.278a.534.534,0,0,0,.755.755l2.278-2.278,2.278,2.278a.534.534,0,0,0,.755-.755Z" transform="translate(-11.285 -11.289)" fill="rgba(255,255,255,0.5)"/>
</svg>
          {{ RealEstate.name }}
        </span>
      </div>
      <div class="list-city overflow-y-scroll scrollbar-hide h-screen" id="list-area" v-if="isShowArea">
        <ul v-if="realEstates.length > 0">
          <li v-for="(realEstate , i) in realEstates" :key="i" class="items flex flex-row-reverse gap-x-4" @click="handleSelecteRealEstate(realEstate)" >
            <div>
              <h6>{{ realEstate.name }}</h6>
              <span>{{realEstate.city + ',' + realEstate.area_name + ',' + realEstate.address }}</span>
            </div>
            <img class="w-9 h-9 rounded-full" :src="baseURL + realEstate.logo" alt="">
          </li>
        </ul>
        <div class="text-center text-white" v-else>{{textArea}}</div>
        <a class="btn-custom" @click="submitSelecetedRealEstate"  style="position: absolute; width: 100%; bottom: 25%; left: 0;">
          ثبت بنگاه های تایید شده
          <span class="svg-btn-icon2 bg-orange transform rotate-0">
         <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
        </a>
      </div>
      <div class="text-center text-white" v-else>لطفا اول شهر را خود را انتخاب کنید</div>

    </div>

  </div>

</template>

<script>
import axios from '../../plugins/axios'
export default {
  name: "SelectRealEstatesCustomer",
  components: {

  },
  data() {
    return {
      cities : null,
      isShowCities : false,
      filter : '',
      isShowArea : false,
      selectedCity : 'انتخاب شهر',
      realEstates : [],
      textArea : 'بنگاه مورد نظر خود را جستجو کنید',
      selectedRealEstates : [],
      timer : null,
      baseURL : this.$store.state.general.baseURL
    }
  },
  methods:{
    handleSelectCity(data){
      this.selectedCity = data.name;
      this.isShowCities = false;
      this.isShowArea = true
      this.selectedRealEstates = [];
      localStorage.setItem('city',JSON.stringify(data))
    },
    filterRealEstate(e) {
      if(this.timer){
        clearTimeout(this.timer);
        this.timer = null
      }
      this.timer = setTimeout(async ()=>{
        if (e.target.value !== '') {
          if (this.isShowArea) {
            await axios.post('/real-estates/filter',{
              filter :{
                name : {
                  operator : 'LIKE',
                  value : '%'+ e.target.value + '%'
                },
                city : {
                  operator: '=',
                  value : this.selectedCity
                }
              }
            }).then(async ({data}) => {
              this.realEstates = await data.data.data
              if (this.realEstates.length <= 0) {
                this.textArea = 'بنگاه مورد نظر یافت نشد'
              }
            })
          }
        } else if (e.target.value === '') {
          this.realEstates = []
          this.textArea = "لطفا بنگاه خود را جستجو کنید"
        }
      },800)


    },
    handleSelecteRealEstate(data){
      let checkRealEstate = this.selectedRealEstates.find(item => item === data)
      if (this.selectedRealEstates.length <= 2){
        if(checkRealEstate === undefined){
          this.selectedRealEstates.push(data)
        }else{
          this.$toast.error('این بنگاه قبلا انتخاب شده')
        }
      }else{
        this.$toast.error('بیشتر از سه بنگاه نمیتوانید انتخاب کنید')
      }
    },
    removeSelectedRealEstate(data){
      let index = this.selectedRealEstates.indexOf(data)
      this.selectedRealEstates.splice(index,1)
    },
    async submitSelecetedRealEstate(){
      let realEstatesIds = []
      this.selectedRealEstates.forEach(item => realEstatesIds.push(item.id))
      axios.post('/ad-requests/manual',{
        province : this.$store.state.addRequest.province,
        city : this.$store.state.addRequest.city,
        contact : this.$store.state.addRequest.contact,
        address : this.$store.state.addRequest.address,
        visit_time : this.$store.state.addRequest.visit_time,
        description : this.$store.state.addRequest.description,
        realEstateIds : realEstatesIds,
        geoposition : this.$store.state.addRequest.geoposition,
      }).then((data)=>{
        this.$toast.success('درخواست شما به بنگاه های انتخاب شده ارسال شد')
      })
      this.$router.push({name : 'HomeCustomer'})
    }
  },
  computed : {
    filterCities(){
      return this.cities.filter(item => item.name.includes(this.filter))
    }
  },
  async mounted() {
    await axios.post('options/cities').then(({data})=>{
      this.cities = data.data;
    })
    let city = JSON.parse(localStorage.getItem('city'))
    if ('city' in localStorage){
      this.selectedCity = city.name
      this.isShowArea = true
    }

  }
}
</script>


<style scoped lang="scss">
.search__wrapper{
  input{
    direction: rtl;
    margin-right: 1rem;
  }
  .selectCity{
    white-space: nowrap;
    height: 100%;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    position: relative;
    top: 3px;
    margin-left: 0.5rem;
    cursor: pointer;
    &:after{
      content: '';
      width: 1px;
      background-color: #e4e4e424;
      height: 58px;
      top: -22px;
      position: absolute;
      left: -13px;
    }
  }

}
#list-area  {
  ul{
    .items{
      &:before{
        top: 5px !important;
      }
    }
  }
}
</style>
